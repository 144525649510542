import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import initFirebase from "./initFirebase";

export default function getFirebaseAuth(tenantId?: string) {
  initFirebase();
  const auth = getAuth(getApp());
  if (tenantId) {
    auth.tenantId = tenantId;
  }

  return auth;
}
