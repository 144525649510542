"use client";
import { Logo } from "@/shared_components/Logo";
import { useAuth } from "@/shared_lib/firebase/authContext";
import getFirebaseAuth from "@/shared_lib/firebase/getFirebaseAuth";
import { GetUserResponse } from "@/shared_lib/interfaces";
import { getMagicLink, getUser } from "@/shared_lib/services/userService";
import { useStore } from "@/shared_lib/store/store";
import { AUTH_ERROR_CODES_MAP } from "@/shared_lib/utils/authErrors";
import { Button } from "@repo/ui";
import {
  AuthError,
  GoogleAuthProvider,
  UserCredential,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopup,
} from "firebase/auth";
import { useRouter, useSearchParams } from "next/navigation";
import { FormEvent, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const EMAIL_NOT_VERIFIED_ERROR = "Email not verified";

export default function LoginPage() {
  const searchParams = useSearchParams();
  const reload = searchParams.get("reload");
  const emailAdded = searchParams.get("email") || "";
  const domainData = useStore((state) => state.domainData);
  const { setUser } = useAuth();
  const provider = new GoogleAuthProvider();
  const router = useRouter();
  const [email, setEmail] = useState<string>(emailAdded);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const auth = getFirebaseAuth(domainData.giTenantId);
  const orgId = domainData?.orgId;
  const [inviteSent, setInviteSent] = useState<boolean>(false);

  useEffect(() => {
    if (auth) {
      setLoadingAuth(false);
    } else {
      setError("Failed to initialize Firebase authentication.");
    }
  }, [auth]);

  useEffect(() => {
    if (reload) {
      router.refresh();
    }
  }, [reload, router]);

  useEffect(() => {
    if (!loadingAuth) {
      handleLoginWithEmailLinkCallback().catch((e) => {
        setError("Error handling email link callback. " + e);
        setLoading(false);
      });
    }
  }, [loadingAuth]);

  async function updateSession(userId: string, sessionId: string, token: string, userData: any) {
    try {
      await fetch("/api/update-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, sessionId, token, userData }),
      });
    } catch (e) {
      console.error("Failed to update session:", e);
      throw new Error("Failed to update session.");
    }
  }

  async function getSessionId(userId: string): Promise<string> {
    try {
      const response = await fetch("/api/get-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      const data = await response.json();
      return data.sessionId;
    } catch (e) {
      console.error("Failed to get session ID:", e);
      throw new Error("Failed to get session ID.");
    }
  }

  async function firebaseLogin(
    credential: UserCredential,
    orgId: string,
  ): Promise<{ error: string } | GetUserResponse> {
    try {
      const idToken = await credential.user.getIdToken();
      await fetch("/api/login", {
        headers: {
          Authorization: `Bearer ${idToken}`,
          cors: "no-cors",
        },
      });

      let userResponse = await getUser({ orgId, role: "Admin", token: idToken, userId: "self", showToast: true });

      const { email_verified, is_admin } = userResponse;

      if (!email_verified && !is_admin) {
        return { error: EMAIL_NOT_VERIFIED_ERROR };
      }

      const userId = credential.user.uid;
      const existingSessionId = await getSessionId(userId);
      const sessionId = existingSessionId || uuidv4();

      const user = { ...userResponse, token: idToken } as any;

      await updateSession(userId, sessionId, idToken, user);
      setUser(user);

      // if (userResponse.current_subscription === null) {
      //   router.push("/onboarding");
      // } else {
      //   roleBasedRedirection(user);
      // }

      return user;
    } catch (e) {
      console.error("Firebase login failed:", e);
      throw new Error("Firebase login failed.");
    }
  }

  function getRedirectLink(hostName: string, orgId: string, isDevelopment: boolean, encodedEmail: string) {
    if (hostName.includes("preview.amigo.ai")) {
      return `https://${hostName}/login?email=${encodedEmail}`;
    }
    if (isDevelopment) {
      return `https://amigo.local.ai:3000/login?email=${encodedEmail}`;
    } else {
      return `https://${orgId}.amigo.ai/login?email=${encodedEmail}`;
    }
  }

  async function handleLoginWithEmailLink(event: FormEvent) {
    event.preventDefault();
    setError("");
    setLoading(true);
    setInviteSent(true);

    if (!email) {
      setError("Email is required.");
      setLoading(false);
      return;
    }

    window.localStorage.setItem("emailForSignIn", email);
    const isDevelopment = process.env.NODE_ENV === "development";

    const encodedEmail = encodeURIComponent(email);

    const hostName = window.location.hostname;
    const redirectLink = getRedirectLink(hostName, orgId, isDevelopment, encodedEmail);

    try {
      const payload = {
        email,
        redirect_link: redirectLink,
      };
      const response = await getMagicLink(orgId, "Admin", payload);
      console.log(response);
    } catch (e) {
      console.error("Failed to get magic link:", e);
      setError("Failed to send magic link. Please try again.");
      setLoading(false);
    }
  }

  const roleBasedRedirection = (user: { is_admin: boolean }) => {
    if (user.is_admin) {
      router.push("/admin/home");
    } else {
      router.push("/home");
    }
  };

  async function handleLoginWithEmailLinkCallback() {
    try {
      if (!isSignInWithEmailLink(auth, window.location.href)) {
        return;
      }
      setLoading(true);

      let callbackEmail = email || window.localStorage.getItem("emailForSignIn");

      if (!callbackEmail) {
        setError("Email for sign-in is missing.");
        setLoading(false);
        return;
      }

      const result = await signInWithEmailLink(auth, callbackEmail, window.location.href);
      const user = await firebaseLogin(result, orgId);

      if ("error" in user) {
        setError(user.error);
        setLoading(false);
        return;
      }

      const idToken = await result.user.getIdToken();

      window.localStorage.removeItem("emailForSignIn");

      if (!idToken) {
        throw new Error("No id token found");
      }

      roleBasedRedirection(user);
    } catch (error) {
      console.error("Error in email link callback:", error);
      setError("Failed to complete login with email link. Please try again.");
      setLoading(false);
    }
  }

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      const user = await firebaseLogin(result, orgId);

      if ("error" in user) {
        setError(user.error);
        setLoading(false);
        return;
      }

      if (!idToken) {
        throw new Error("No id token found");
      }

      roleBasedRedirection(user);
    } catch (error) {
      const authError = error as AuthError;
      if (authError.code in AUTH_ERROR_CODES_MAP) {
        setError(AUTH_ERROR_CODES_MAP[authError.code] || "An unknown error occurred. Please try again.");
      } else {
        setError("An unknown error occurred. Please try again.");
      }
      setLoading(false);
    }
  };

  if (loadingAuth) {
    return (
      <div className="flex h-screen items-center justify-center">
        <svg
          className="text-primary-600 -ml-1 mr-3 h-5 w-5 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2zm0 0a8 8 0 018 8v2a10 10 0 00-10-10h2z"
          ></path>
        </svg>
        <span className="text-gray-900 dark:text-white">Loading...</span>
      </div>
    );
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-8">
      <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
        <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
          {loading ? (
            inviteSent ? (
              <div className="flex h-full items-center justify-center">
                <span className="text-gray-900 dark:text-white">Please check your email inbox!</span>
              </div>
            ) : (
              <div className="flex h-full items-center justify-center">
                <svg
                  className="text-primary-600 -ml-1 mr-3 h-5 w-5 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V4a10 10 0 00-10 10h2zm0 0a8 8 0 018 8v2a10 10 0 00-10-10h2z"
                  ></path>
                </svg>
                <span className="text-gray-900 dark:text-white">Authenticating...</span>
              </div>
            )
          ) : (
            <>
              <Logo position="center" />

              <Button type="submit" variant="primary" className="w-full" onClick={handleGoogleLogin}>
                Login with Google
              </Button>

              <div className="flex items-center justify-center space-x-2">
                <hr className="w-1/3 border-gray-300 dark:border-gray-600" />
                <span className="text-gray-400 dark:text-gray-500">or</span>
                <hr className="w-1/3 border-gray-300 dark:border-gray-600" />
              </div>

              <form onSubmit={handleLoginWithEmailLink} className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                    placeholder="name@company.com"
                    required
                  />
                </div>

                {error && (
                  <div
                    className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                    role="alert"
                  >
                    <span className="block sm:inline">{error}</span>
                  </div>
                )}
                <Button type="submit" variant="primary" className="w-full">
                  Get Magic Link
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
