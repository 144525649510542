import { useStore } from "@/shared_lib/store/store";
import { cn } from "@repo/utils";
import Image from "next/image";
interface LogoProps {
  altText?: string;
  overrideLogoUrl?: string;
  position?: string;
}

export const Logo = ({ altText, overrideLogoUrl, position }: LogoProps) => {
  const domainData = useStore((state) => state.domainData);
  const logo = overrideLogoUrl || domainData?.logo || "";
  const src = `data:image/png;base64,${logo}`;

  return (
    <div>
      <Image
        className={cn("h-10 w-auto", position === "center" ? "mx-auto" : "mr-4")}
        src={src}
        width={100}
        height={120}
        alt={altText || "logo"}
      />
    </div>
  );
};
